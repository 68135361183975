import { useEffect, useState } from "react";
import { Col, Row, Spin, Typography, notification, Anchor, Divider } from "antd";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { formatPhoneNumber } from "../../../utils/helpers";
import { OtpVerification, cognitoSignIn, resendOtp } from "../../../services/app.service";
import { config } from "../../../utils/config";
import { setCognitoPayload, setUser, setUserSigninState } from "../../../redux/appSlice";

import "./OTP.scss";
import Banner from "../../../components/shared/Banner/Banner";
import { Auth } from "aws-amplify";

const { Link } = Anchor;

const OTPVerification: React.FC = () => {
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const state = useSelector((state: any) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const onOTPSubmit = async () => {
    try {
      setLoader(true);
      

          let loggedUser = null
          if (state?.userSigninState?.challengeName === 'CUSTOM_CHALLENGE') {
            loggedUser = await Auth.sendCustomChallengeAnswer(state?.userSigninState, otp);
            if(loggedUser.attributes){
              if(loggedUser.attributes['custom:agent'] !== 'NOT_PROVIDED'){
                notification.error({
                  message: 'User Already Registered',
                });
                navigate('/')
                return
              }
              else{
                saveLoginUserDataAndRedirect(loggedUser)
                dispatch(setUserSigninState(null))
                navigate("/profile-update");
              }
            }
            else{
              notification.error({
                message: 'Incorrect username or password',
              });
            }
          }
                
      setLoader(false);
    } catch (ex: any) {
      setLoader(false);
      console.log(ex?.message)
      if(ex?.message === 'Incorrect username or password.'){
        notification.error({
          message: 'Incorrect username or password',
        });
      }
      if(ex?.message === 'Invalid session for the user.'){
        notification.error({
          message: 'You have tried maximum time. Please resend the otp again',
        });
      }

      }

    }
  


  const saveLoginUserDataAndRedirect = (user:{[key:string]:any}) =>{
    const userObj = {
      username: user.attributes.phone_number,
      attributes: {
        phone_number: user.attributes.phone_number,
        given_name: user.attributes.given_name,
        family_name: user.attributes.family_name,
        gender: user.attributes.gender,
        birthdate: user.attributes.birthdate,
        address: user.attributes.address,
        otpData: user.attributes['custom:otpData'],
      }
    }
    localStorage.setItem('userAttributes',JSON.stringify(userObj))
    localStorage.setItem('username',user?.attributes?.phone_number)
    dispatch(setUser(user?.attributes))
    dispatch(setCognitoPayload(userObj));
  }

  const onResend = async () => {
    const phone = `${state.phone}`;
    try {
      await dispatch(setUserSigninState(null))
      setLoader(true);
      const user = await cognitoSignIn({ username: phone })
     await dispatch(setUserSigninState(user))
      
      setLoader(false);
      notification.success({
        message: "OTP Resent Successfully",
      });
    } catch (ex: any) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      onOTPSubmit();
    }
  }, [otp, navigate]);
  return (
    <Spin spinning={loader}>
      <div className="topHeading">
        <div className="copia-logo" />
      </div>

      <div className="container-middle">
        <div className="copia-otp-page">



          <div className="copia-otp-heading">
            <Typography.Title level={3}>
              Let’s do a quick verification
            </Typography.Title>
            <Typography.Paragraph>
              Please wait for an SMS from Copia to{" "}
              {formatPhoneNumber(state.phone)} with your verification code
            </Typography.Paragraph>
          </div>

          <div className="copia-otp-container mt-24px">
            <OTPInput
              containerStyle={{
                justifyContent: "space-evenly˝",
              }}
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              inputType="number"
            />
          </div>
          <b>Didn’t receive the code?</b>
          <Typography.Paragraph
            className="copia-otp-resent-button"
            onClick={onResend}
          >
            Resend the Code
          </Typography.Paragraph>

          <div className="mt-24px">
            <Row justify={"center"} align={"middle"}>
              <Col span={24}>
                <Banner url={`${config.REACT_APP_BANNER_PRODUCT_PROMO}`} />
              </Col>
            </Row>
          </div>
        </div>

      </div>

      <section className="copia-registration-footer-content">
        <div className="copia-privacy-policy-links">
          <span>
            <Link
              href="https://copia.co.ke/privacy-policy/"
              title="Privacy policy"
            />
          </span>
          <Divider type="vertical" />
          <span>
            <Link
              href="https://copia.co.ke/terms-conditions/"
              title="Terms & Conditions"
            />
          </span>
        </div>
        <div style={{ fontSize: 14 }}>Copyright @ 2023 Copia Global</div>
      </section>
    </Spin>
  );
};

export default OTPVerification;
