// src/slices/appSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Define your initial state here
  otpSent: false,
  phone: "",
  agent: {
    id: null,
    name: "",
    phone: "",
    partner_type: "",
    is_active: true,
    create_date: "",
    create_uid: null,
    write_date: "",
    write_uid: null,
    is_employee: false,
  },
  user: null,
  customer: {
    id: null,
    name: "",
    phone: "",
    partner_type: "",
    is_active: true,
    create_date: "",
    create_uid: null,
    write_date: "",
    write_uid: null,
    is_employee: false,
  },
  cognitoPayload: {
    username: "",
    attributes: {
      email: "",
      phone_number: "",
      given_name: "",
      family_name: "",
      gender: "",
      birthdate: "",
      address: "",
      otpData: "",
    },
  },
  promos: [],
  otpResponse: {},
  userSigninState:null
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    // Define your reducer actions here
    dispatchOTPSentAction: (state, action) => {
      state.otpSent = true;
      state.phone = action.payload.phone;
    },
    resetOTPAction: (state, action) => {
      state.otpSent = false;
      state.phone = "";
    },
    dispatchFillProfileAuto: (state, action) => {
      const { payload } = action;
      state.agent = payload?.agent;
      state.customer = payload?.customer;
    },
    setCognitoPayload: (state, action) => {
      const { payload } = action;
      state.cognitoPayload = payload;
    },
    setPromos: (state, action) => {
      const { payload } = action;
      state.promos = payload;
    },
    setOtpResponse: (state, action) => {
      const { payload } = action;
      state.otpResponse = payload;
    },
    setUser: (state, action) => {
      const { payload } = action;
      state.user = payload;
    },
    logout:(state) =>{
      state.otpSent = false
      state.phone = ""
      state.agent = {
        id: null,
        name: "",
        phone: "",
        partner_type: "",
        is_active: true,
        create_date: "",
        create_uid: null,
        write_date: "",
        write_uid: null,
        is_employee: false,
      }
      state.user = null
      state.customer = {
        id: null,
        name: "",
        phone: "",
        partner_type: "",
        is_active: true,
        create_date: "",
        create_uid: null,
        write_date: "",
        write_uid: null,
        is_employee: false,
      }
      state.cognitoPayload = {
        username: "",
        attributes: {
          email: "",
          phone_number: "",
          given_name: "",
          family_name: "",
          gender: "",
          birthdate: "",
          address: "",
          otpData: "",
        }
      }
      state.otpResponse = {}
    },
    setUserSigninState :(state,action) =>{
      state.userSigninState = action.payload 
    }
  },
});

export const {
  dispatchOTPSentAction,
  resetOTPAction,
  dispatchFillProfileAuto,
  setCognitoPayload,
  setPromos,
  setOtpResponse,
  setUser,
  logout,
  setUserSigninState
} = appSlice.actions;
export default appSlice.reducer;
