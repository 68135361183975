import { IDialogProps } from "./Dialog.interface";
import DialogPointer from "../../../assets/images/dialog-pointer.svg";
import "./Dialog.scss";

const Dialog: React.FC<IDialogProps> = (props: IDialogProps) => {
  return (
    <div className="welcome-screen-dialog">
      <div className="overlap-group">
        <div className="div-wrapper">
          {typeof props.text === "string" ? (
            <p className="p">
              {props.text}{" "}
              {props.emphesizedText ? <b>{props.emphesizedText} </b> : <></>}
            </p>
          ) : (
            props.text
          )}
        </div>
        <div
          className="rozzie"
          style={
            props.circleCustomPosition
              ? { top: props.circleCustomPosition }
              : {}
          }
        >
          <img className="untitled" alt="Untitled" src={props.image} />
        </div>
        <img
          className="vector"
          style={
            props.pointerCustomPosition
              ? { top: props.pointerCustomPosition }
              : {}
          }
          alt="Vector"
          src={DialogPointer}
        />
      </div>
    </div>
  );
};
export default Dialog;
