export const formatPhoneNumber = (phoneNumber: string): string => {
  // Check if the phone number is valid and has a length of 12 characters
  //   if (phoneNumber.length === 12 && /^\d+$/.test(phoneNumber)) {
  const formattedPhoneNumber =
    phoneNumber.substring(0, 4) + "****" + phoneNumber.substring(8);
  return formattedPhoneNumber;
  //   } else {
  // return "Invalid phone number";
  //   }
};
interface INameInterface {
  firstName: string;
  lastName: string;
}
export const splitName = (name: string | undefined | null): INameInterface => {
  if (name) {
    const firstName = name.split(" ")[0];
    const lastName = name.split(" ")[1];
    return {
      firstName,
      lastName,
    };
  } else {
    return {
      firstName: "",
      lastName: "",
    };
  }
};

export const getFormattedDate = (date: string) => {
  if (!date) return "";
  const birtdate = new Date(date);
  const year = birtdate.getFullYear();
  const month = String(birtdate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and format as 2 digits
  const day = String(birtdate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const obscurePhoneNumber = (phoneNumber: string): string => {
  const cleanNumber = phoneNumber.replace(/\D/g, "");
  if (cleanNumber.length !== 12) {
    return "Invalid phone number format";
  }
  // const obscuredPart = cleanNumber.slice(6, 9); // Replace characters from index 6 to 8 (inclusive).
  const obscuredPhoneNumber = `+254${cleanNumber.substring(
    3,
    6
  )}***${cleanNumber.substring(9)}`;
  return obscuredPhoneNumber;
};

export const trimString = (inputString: string, length: number) => {
  if (inputString.length <= length) {
    return inputString;
  } else {
    return inputString.slice(0, length) + "…";
  }
};

export const isEighteenOrPlus = (dateOfBirth: string) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age >= 18;
};
