import { Button, Col, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import { config } from "../../../utils/config";
import PrivacyPolicy from "../../../components/shared/PrivacyPolicy/PrivacyPolicy";
import Banner from "../../../components/shared/Banner/Banner";
import SVGToComponent from "../../../components/shared/icons/icons";
import CopiaAppDownload from "../../../assets/images/download-copia-app.svg";
import { logOutUser, rewardWithQueue } from "../../../services/app.service";
import { useEffect } from "react";
import _ from "lodash";
import rewardImg from '../../../assets/images/reward_partner_id.svg'

import "./Thankyou.scss";
import { useLocation, useNavigate } from "react-router";

const Thankyou: React.FC<any> = () => {
  const state = useSelector((state: any) => state.app.cognitoPayload);

  const location = useLocation()
  const navigate = useNavigate()

  

  const getReward = async () => {
    try {
      await logOutUser()
      await rewardWithQueue({
        customer_phone: state.attributes?.phone_number,
      });
      setTimeout(() =>{
        window.location.replace(process.env.REACT_APP_REDIRECT_URL!);
      },120000)
    } catch (ex) {
      console.log(ex);
      setTimeout(() =>{
        window.location.replace(process.env.REACT_APP_REDIRECT_URL!);
      },120000)
    }
   
  };

  useEffect(() => {
    console.log(location?.state?.id)
    if(!location?.state?.isConfirm) navigate('/profile-update') 
    if (location?.state?.id && location?.state?.id !== 'false') {
      getReward();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Row justify={"center"} className="copia-thankyou-main-container">
      <Col span={24}>
        <Row justify={"center"} align={"middle"}>
          <div className="copia-logo" />
        </Row>
      </Col>
      <Col span={24}>
        <Typography.Title level={4} style={{ margin: 0 }}>
          Congratulations
        </Typography.Title>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {state.attributes.given_name || "winnie!"}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <img
          src={
            location?.state?.id && location?.state?.id !== 'false' ?
            rewardImg
            :
            "https://media.profile.copiaglobal.io/static/FreeDataOfferBanner.png"
          }
          alt="reward"
          height={200}
          width={250}
        />
      </Col>
      <Col span={24}>
        <Typography.Paragraph>
          Your 100MB of free data will be in your phone before long. Keep an eye
          on your inbox for a confirmation SMS and tap on the link above to
          download Copia App for a super charged shopping experience with
          Special App-Only Offers and no data costs.
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <Banner url={config.REACT_APP_BANNER_PRODUCT_PROMO || ""} />
      </Col>
      <Col span={24}>
        <Typography.Title level={4}>
          Don’t miss out on this Copia offers. Download the Copia app and start
          saving
        </Typography.Title>
      </Col>
      <Col span={24} className="copia-promotion-app-download-button">
        <Button
          type="primary"
          className="copia-button"
          onClick={() =>
            (window.location.href = config.REACT_APP_PLAYSTORE_LINK || "")
          }
        >
          Download Copia App <SVGToComponent icon={CopiaAppDownload} />
        </Button>
      </Col>
      <Col span={24}>
        <div className="app-class">
          <img
            alt="Thank you"
            src="/assets/images/playstore.png"
            onClick={() =>
              (window.location.href = config.REACT_APP_PLAYSTORE_LINK || "")
            }
            height={100}
            width={"80%"}
          />
        </div>
      </Col>
      <Col span={24}>
        <PrivacyPolicy />
      </Col>
    </Row>
  );
};
export default Thankyou;
