import {
  Link,
  // Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Registration from "../pages/Registration";
import OTPVerification from "../pages/Registration/OTPVerification/OTP";
import Profile from "../pages/Registration/Profile/Profile";
import DeliveryLocation from "../pages/Registration/DeliveryLocation/DeliveryLocation";
import Confirmation from "../pages/Registration/Confirmation/Confirmation";
import Thankyou from "../pages/Registration/Thankyou/Thankyou";
import React from "react";
import Welcome from "../pages/Welcome";
import Promotion from "../pages/Promotion";
import Reminder from "../pages/Reminder/Reminder";
import PrivateRoutes from "./PrivateRoute";
import MapLocation from "../pages/Registration/MapLocation/MapLocation";
import PublicRoute from "./PublicRoute";

const WrongRoute: React.FC = () => (
  <div>
    Nothing found please <Link to={"/"}>Click Here</Link>
  </div>
);
const RootRoute = () => {
  return (
    <Router>
      <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<Welcome />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/phone-verification" element={<OTPVerification />} />
      </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/profile-update" element={<Profile />} />
          <Route path="/delivery-location" element={<DeliveryLocation />} />
          <Route path="/map-location" element={<MapLocation />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="/promotion" element={<Promotion />} />
          <Route path="/reminder" element={<Reminder />} />
        </Route>
        <Route path="*" element={<WrongRoute />} />
      </Routes>
    </Router>
  );
};

export default RootRoute;
