import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  Select,
  Button,
  Spin,
  notification,
} from "antd";
import "./Profile.scss";
import { useRef, useState, useEffect } from "react";
import type { FormInstance } from "antd/es/form";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  dispatchFillProfileAuto,
  setCognitoPayload,
} from "../../../redux/appSlice";
import { fetchExistingUser } from "../../../services/app.service";
import {
  getFormattedDate,
  isEighteenOrPlus,
  splitName,
} from "../../../utils/helpers";
import moment from "moment";
import CopiaDatePicker from "../../../components/shared/DatePicker/CopiaDatePicket";

const { Option } = Select;

const formItemStyles: React.CSSProperties = {
  marginBottom: 10,
};
const inputStyles: React.CSSProperties = {
  height: 40,
};
const Profile: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<FormInstance>(null);
  const navigate = useNavigate();
  const state = useSelector((state: any) => state.app);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [existing, setExisting] = useState<boolean>(false);
  const [isAgent,setIsAgent] = useState<boolean>(false)
  const [agentLocation,setAgentLocation] = useState<null | {[key:string]:any} >(null)
  const [partId,setPartId] = useState(null)

  const onGenderChange = (value: string) => {
    switch (value) {
      case "male":
        formRef.current?.setFieldsValue({ note: "Hi, man!" });
        break;
      case "female":
        formRef.current?.setFieldsValue({ note: "Hi, lady!" });
        break;
      case "other":
        formRef.current?.setFieldsValue({ note: "Hi there!" });
        break;
      default:
        break;
    }
  };

  const onSubmit = (values: any) => {
    setLoading(true);

    let DOB = ""
    if (values.day && values.month && values.year) {
      const day = getFormattedDate(values.day).split("-")[2];
      const month = getFormattedDate(values.month).split("-")[1];
      const year = getFormattedDate(values.year).split("-")[0];
      DOB = `${year}-${month}-${day}`
  
    }

    if (DOB && !isEighteenOrPlus(DOB)) {
      notification.error({
        message: "You must be 18 years or older to register.",
      });
      setLoading(false);
      return;
    }

    try {
      const cognitoPayload = {
        username: values.phoneNumber,
        attributes: {
          email: values.email || "",
          phone_number: values.phoneNumber,
          given_name: values.firstName,
          family_name: values.lastName,
          gender: values.gender,
          birthdate: DOB || "",
          address: "",
          "custom:otpData": JSON.stringify(state.otpResponse),
          ...(isAgent ? {agent:JSON.stringify({
            geolocation:{
              lat:agentLocation!.latitude,
              lng:agentLocation!.longitude
            },
            name:agentLocation!.name,
            display_name:agentLocation?.display_name ?? agentLocation?.name
          })} :
          {
            agent: JSON.stringify({
              "geolocation": 
                  {
                      "lat":"", 
                      "lng":""
                  },
                      "name": "",
                      "display_name": "" 
             })
          }
          
          ),
          "custom:partner_id": partId ? JSON.stringify(partId) : "false"
        }
      };
      dispatch(setCognitoPayload(cognitoPayload));
      if(agentLocation){
        if(isAgent) navigate('/delivery-location')
        else navigate("/map-location");
      setLoading(false);
      return
      }
      
       navigate('/delivery-location')
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const setInitialValuesByState = (values: any) => {
    form.setFieldsValue({
      phoneNumber:localStorage.getItem('username')  ?? state.phone,
      firstName: splitName(values.customer?.name).firstName === 'NOT_PROVIDED' ? '' : splitName(values.customer?.name).firstName,
      lastName: splitName(values.customer?.name).lastName === 'NOT_PROVIDED' ? '' : splitName(values.customer?.name).lastName,
      gender:
        (values.customer?.gender === 'NOT_PROVIDED' ? '' : values.customer?.gender) 
        ||
        (state.cognitoPayload.attributes.gender === 'NOT_PROVIDED' ? '' : state.cognitoPayload.attributes.gender)
        ||
        null,
      alternativePhoneNumber:
        values.customer?.alternative_phonenumber ||
        state.cognitoPayload.attributes.phone_number ||
        "",
      birthdate: values.customer?.birthdate
        ? moment(getFormattedDate(values.customer?.birthdate))
        : state.cognitoPayload.attributes?.birthdate
        ? moment(getFormattedDate(state.cognitoPayload.attributes?.birthdate))
        : null,
      email:
        values.customer?.email || state.cognitoPayload.attributes.email || ""
    });
  };

  const setInitialValuesByCognito = (values: any) => {
        form.setFieldsValue({
      phoneNumber: values.attributes.phone_number || state.phone,
      firstName: values.attributes.given_name || "",
      lastName: values.attributes.family_name || "",
      gender: values.attributes.gender || null,
      alternativePhoneNumber:
        values.attributes.phone_number || "",
      birthdate: values.attributes?.birthdate
        ? moment(getFormattedDate(state.cognitoPayload.attributes?.birthdate))
        : null,
      email: values?.attributes?.email || "",
    });
  };

  const fetchExistingUserEffect = async () => {
    try {
      setLoading(true);
      const body = { customer_phone: localStorage.getItem('username') ?? state.phone };
      const response = await fetchExistingUser(body);
      if(response.data.data[0]?.agent.active){
        setAgentLocation(response.data.data[0]?.agent)
        setPartId(response.data.data[0]?.customer?.id)
        setIsAgent(true)
      } 
      else {
        setAgentLocation(null)
        setIsAgent(false)
      } 
      const responseParsed = { ...response.data?.data[0] };
      setExisting(true);
      dispatch(dispatchFillProfileAuto(responseParsed));
      setInitialValuesByState(responseParsed);
      setLoading(false);

    } catch (err: any) {
       
        let responseParsed : any = {
          phone: state.phone,
          customer: {
            name: '',
            gender: '',
            alternative_phonenumber: '',
            birthdate: '',
            email: ''
          }
        };

          const attr = JSON.parse(localStorage.getItem('userAttributes')!)?.attributes ?? state.cognitoPayload.attributes;


          responseParsed = {
            phone: attr.phone_number ?? state.phone,
            customer: {
              name: attr?.given_name+" "+attr?.family_name,
              gender: attr.gender,
              alternative_phonenumber: attr?.phone_number,
              birthdate: attr?.birthdate,
              email: attr?.email
            }
          };

        setExisting(false);
        setInitialValuesByState(responseParsed);

      //navigate("/registration");
      setLoading(false);
    }
  };

  useEffect(() => {
      fetchExistingUserEffect();
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <Spin spinning={loading}>
        <Row
          justify={"space-between"}
          align={"middle"}
          style={{ marginBottom: 10 }}
        >
          <Col span={20}>
            <Typography.Title
              level={4}
              style={{ textAlign: "left", margin: 0 }}
            >
              
              {existing ? "Update your profile" : "Add your profile"}
            </Typography.Title>
          </Col>
          
          <Col span={4}>
            <div className="copia-profile-update-side-logo" />
          </Col>
        </Row>
        <Row justify={"start"}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Typography.Paragraph>
              Your profile information
            </Typography.Paragraph>
          </Col>
          <Col span={24}>
        
            <Form layout="vertical" onFinish={onSubmit} form={form}>
              <Form.Item
                name={"phoneNumber"}
                label="Phone number"
                style={formItemStyles}
              >
                <Input
                  type="text"
                  value={state.phone}
                  placeholder="Enter your phone number"
                  style={inputStyles}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name={"firstName"}
                label="First name"
                style={formItemStyles}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  type="text"
                  value={splitName(state.customer?.name).firstName}
                  placeholder="First Name"
                  style={inputStyles}
                />
              </Form.Item>
              <Form.Item
                name={"lastName"}
                label="Last name"
                style={formItemStyles}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  type="text"
                  value={splitName(state.customer?.name).lastName}
                  placeholder="Last Name"
                  style={inputStyles}
                />
              </Form.Item>
              <Form.Item
                name="gender"
                rules={[{ required: true }]}
                style={{ textAlign: "left" }}
                label="Select gender"
              >
                <Select
                  placeholder="Select Gender"
                  onChange={onGenderChange}
                  allowClear
                  style={inputStyles}
                >
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={"alternativePhoneNumber"}
                label="Alternative phone number"
              >
                <Input
                  type="text"
                  placeholder="Alternative phone number"
                  style={inputStyles}
                />
              </Form.Item>
              <Form.Item label="Select Date of birth" name="birthdate">
                <CopiaDatePicker />
              </Form.Item>
              <Form.Item
                label="Got an email address? (optional)"
                name={"email"}
              >
                <Input
                  type="email"
                  placeholder="Your email address"
                  style={inputStyles}
                />
              </Form.Item>
              <Button className="copia-profile-submit-button" htmlType="submit">
                Next
              </Button>
            </Form>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default Profile;
