import { Anchor, Divider } from "antd";

const { Link } = Anchor;

const PrivacyPolicy = () => {
  return (
    <section className="copia-registration-footer-content">
      <div className="copia-privacy-policy-links">
        <span>
          <Link
            href="https://copia.co.ke/privacy-policy/"
            title="Privacy policy"
          />
        </span>
        <Divider type="vertical" />
        <span>
          <Link
            href="https://copia.co.ke/terms-conditions/"
            title="Terms & Conditions"
          />
        </span>
      </div>
      <div style={{ fontSize: 14 }}>Copyright @ 2023 Copia Global</div>
    </section>
  );
};

export default PrivacyPolicy;
