import { Anchor, Button, Card, Col, Divider, Row, Typography } from "antd";
import GOOGLE from "../../assets/images/google-play-button.svg";
import HeartIcon from "../../assets/images/heart-icon.svg";
import CopiaAppDownload from "../../assets/images/download-copia-app.svg";

import { config } from "../../utils/config";
import SVGToComponent from "../../components/shared/icons/icons";

import "./Promotion.scss";
import { useEffect } from "react";
import { getProductOffers } from "../../services/app.service";
import { useDispatch, useSelector } from "react-redux";
import { setPromos } from "../../redux/appSlice";

const Promotion: React.FC<any> = () => {
  const promos = useSelector((state: any) => state.app.promos);
  console.log(promos);
  const dispatch = useDispatch();

  const getOffers = async () => {
    try {
      const body = {
        app_id: parseInt(config.REACT_APP_APP_ID || ""),
        last_sync_date: 0.0,
        partner_id: parseInt(config.REACT_APP_PARTNER_ID || ""),
        channel: "ecatalog",
      };
      const response = await getProductOffers(body);
      dispatch(setPromos(response?.data?.promos));
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getOffers();
  }, []);

  return (
    <div className="promotion-main-container">
      <Row justify={"center"} align={"middle"}>
        <Col span={24}>
          <Row justify={"center"} align={"middle"}>
            <div className="copia-logo" />
          </Row>
        </Col>
        <Col span={24} className="promotion-main-title">
          <Typography.Title level={4}>
            Get access to hot deals only available on Copia app
          </Typography.Title>
        </Col>
        <Col span={24} className="promotion-banner-container">
          <div className="promotion-banner-child">
            <div className="promotion-banner-child-texts">
              <div className="frame">
                <div className="up-to-off">
                  Up to 30%
                  <br />
                  off Electronics
                </div>
              </div>
              <div className="app-button">
                <p className="text-wrapper">Only on the Copia App</p>
              </div>
              <div>
                <Button
                  className="go-to-app-button"
                  style={{
                    background: "#FFCC03",
                  }}
               
                  onClick={() =>
                    (window.location.href = config.REACT_APP_PLAYSTORE_LINK || "")
                  }
      
               >
                  Shop Now
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col span={24} className="promotion-products-list">
          <Row justify={"center"} align={"middle"} gutter={15}>
            {promos &&
              promos.map((promo: any, i: number) => {
                if (i < 2) {
                  return (
                    <Col span={12}>
                      <Card className="copia-promotion-product-card">
                        <Row justify={"space-between"} align={"middle"}>
                          <Col span={12}>
                            <div className="copia-promotion-save-text">
                              Save
                              <br />
                              {promo.savings || ""}/-
                            </div>
                          </Col>
                          <Col
                            span={12}
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <SVGToComponent icon={HeartIcon} />
                          </Col>
                          <Col span={24}><img className="promotionProductImage" src={`https://media.copiakenya.com${promo.product_detail.images[0].url}`} alt="Image Unavailable" /></Col>
                          <Col span={24}>{promo.product_detail.name || "-"}</Col>
                          <Col
                            span={12}
                            className="copia-promotion-prices-text"
                          >
                            <Typography.Paragraph className="copia-promotion-promo-price-text mb-0">
                              Ksh {promo.promo_price || ""}
                            </Typography.Paragraph>
                            <Typography.Title
                              level={4}
                              className="copia-promotion-normal-price-text mt0"
                            >
                              Ksh {promo.normal_price || ""}
                            </Typography.Title>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                }
              })}
          </Row>
        </Col>
        <Col span={24}>
          <Typography.Title level={4}>
            Don’t miss out on this Copia offers. Download the Copia app and
            start saving
          </Typography.Title>
        </Col>
        <Col span={24} className="copia-promotion-app-download-button">
          <Button
            type="primary"
            className="copia-button"
            onClick={() =>
              (window.location.href = config.REACT_APP_PLAYSTORE_LINK || "")
            }
          >
            Download Copia App <SVGToComponent icon={CopiaAppDownload} />
          </Button>
        </Col>
        <Col span={24}>
          <img
            src={GOOGLE}
            alt="gogle"
            onClick={() =>
              (window.location.href = config.REACT_APP_PLAYSTORE_LINK || "")
            }
          />
        </Col>
      </Row>
      <section className="copia-registration-footer-content">
        <div className="copia-privacy-policy-links">
          <span>
            <Anchor.Link href="https://google.com" title="Privacy policy" />
          </span>
          <Divider type="vertical" />
          <span>
            <Anchor.Link href="https://google.com" title="Terms & Conditions" />
          </span>
        </div>
        <div style={{ fontSize: 14 }}>Copyright @ 2023 Copia Global</div>
      </section>
    </div>
  );
};

export default Promotion;
