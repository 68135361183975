import { useNavigate } from "react-router";
import { Button, Col, Row, Skeleton } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import AppLogo from "../../assets/images/app-logo.svg";
import Person from "../../assets/images/person-image.svg";
// import Banner from "../../components/shared/Banner/Banner";

import "./Welcome.scss";
import PrivacyPolicy from "../../components/shared/PrivacyPolicy/PrivacyPolicy";
import Dialog from "../../components/shared/Dialog/Dialog";
import { config } from "../../utils/config";
import { Suspense, lazy } from "react";

const Banner = lazy(() => import("../../components/shared/Banner/Banner"));

const Welcome: React.FC<any> = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Row
      justify={"center"}
      className="welcome-screen-main-container"
      style={{
        padding: 10,
      }}
    >
      <Col span={24}>
        <img src={AppLogo} alt="app-log" />
      </Col>
      <Col span={24} className="welcome-screen-title-container">
        <Row justify={"center"}>
          <Col span={24}>
            <div className="label">
              <p className="text-wrapper">
                Save with huge discounts only on the Copia app!
              </p>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Suspense fallback={<Skeleton.Image active />}>
          <Banner url={`${config.REACT_APP_BANNER_PRODUCT_PROMO}`} />
        </Suspense>
      </Col>
      <Col span={24} className="welcome-screen-title-container">
        <Row justify={"center"}>
          <Col span={24}>
            <div className="label">
              <p className="text-wrapper">
                Don’t miss out on this Copia offers. Get the Copia app and start
                saving
              </p>
            </div>
          </Col>
        </Row>
      </Col>
      <Col
        span={24}
        style={{
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Button
          className="copia-registration-submit-button mt-5px"
          onClick={() => navigate("/registration")}
          style={{
            background: "#FECD03",
            fontSize: "24px", // Increase font size to make the text larger
          }}
        >
          Start Here<ArrowRightOutlined />
        </Button>
      </Col>
      <Col span={24}>
        <Dialog
          text="Sign in on Copia App today and unlock an exclusive treat of"
          emphesizedText="100MB free data"
          image={Person}
          circleCustomPosition={45}
          pointerCustomPosition={45}
        />
      </Col>
      <Col span={24}>
        <PrivacyPolicy />
      </Col>
    </Row>
  );
};

export default Welcome;
