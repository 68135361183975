import { useNavigate } from "react-router";
import { Anchor, Button, Col, Divider, Row, Typography, notification } from "antd";
import {
  obscurePhoneNumber,
  splitName,
  trimString,
} from "../../../utils/helpers";

import TruckIcon from "../../../assets/images/truck-icon.svg";
import "../DeliveryLocation/DeliveryLocation.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Banner from "../../../components/shared/Banner/Banner";
import { config } from "../../../utils/config";

const { Link } = Anchor;


const DeliveryLocation: React.FC<any> = () => {

  const state = useSelector((state: any) => state.app);
  const navigate = useNavigate();

  useEffect(()=>{
    if(!state.cognitoPayload.username) {
        notification.error({
            message: "something unexpected happened please try again later.",
          });
        navigate("/registration");
    }
  })

  const handleNext = () => {
    navigate('/map-location')
  }

  const existingDeliveryLocation = ()=> {
    return (
      <div className="copia-registration-main-container">
      <section className="copia-registration-header-content">
        <Row justify={"center"} align={"middle"}>
          <div className="copia-registration-container">
            <Col span={24} style={{ marginBottom: 20 }}>
              <Row justify={"space-between"}>
                <Col span={18}>
                  <Typography.Title
                    level={4}
                    style={{ textAlign: "left", margin: 0 }}
                  >
                    Verify your delivery location
                  </Typography.Title>
                </Col>
                <Col span={4}>
                  <div className="copia-profile-update-side-logo" />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Paragraph
                style={{
                  textAlign: "left",
                }}
              >
                Where your last order was delivered
              </Typography.Paragraph>
            </Col>
            {/*   <Col span={24}>
              <div className="copia-banner-container">
                <Row justify={"start"} gutter={40}>
                  <Col span={2}>
                    <div className="copia-banner-icon" />
                  </Col>
                  <Col span={20}>
                    <Typography.Paragraph className="copia-banner-text">
                      Get 100MB data free once you complete this process
                    </Typography.Paragraph>
                  </Col>
                </Row>
              </div>
            </Col> */}
            <Col span={24}>
            <div className="deliver-to-card">
              <div className="saved-agents">
                <div className="frame">
                  <div className="card-label">
                    <div className="text-wrapper">
                      <div className="text">Deliver to</div>
                    </div>
                    <div className="button">
                      <div className="div" />
                    </div>
                  </div>
                  <div className="frame-2">
                    <div className="text-2">
                      {`${splitName(state.agent?.name).firstName} ${splitName(state.agent?.name).lastName
                        }`}
                    </div>
                    <div className="text-2">
                      {obscurePhoneNumber(state.agent?.phone || "")}
                    </div>
                  </div>
                  <p className="good-width-as-i-have">
                    <span className="span">
                      {state.agent?.business_name} <br />
                    </span>
                    <span className="span">
                      {trimString(state.agent?.address || "", 60)}
                    </span>
                  </p>
                </div>
              </div>
              </div>
            </Col>
            <Col span={24}>
              <Banner url={`${config.REACT_APP_BANNER_PRODUCT_PROMO}`} />
            </Col>
            <br />
            <Col span={24}>
              <div className="deliver-info-card">
                <img
                  className="carbon-delivery"
                  alt="Carbon delivery"
                  width={32}
                  height={32}
                  src={TruckIcon}
                />
                <div className="div">
                  <div className="div-wrapper">
                    <div className="text-wrapper">Order from anywhere</div>
                  </div>
                  <div className="div-wrapper">
                    <p className="p">
                      Your order delivered to the <br />
                      same agent you shop from today
                    </p>
                  </div>
                  <div className="div-wrapper">
                    <p className="p">
                      Your agent still earns <br />
                      commission
                    </p>
                  </div>
                  <div className="div-wrapper">
                    <p className="p">
                      Get access to exclusive <br />
                      offers on the app
                    </p>
                  </div>
                </div>
              </div>
            </Col>
           
            <Col span={24}>
              <Button
                className="copia-profile-submit-button"
                onClick={() => navigate("/confirmation")}
                style={{
                  marginTop: 90,
                  fontSize: "24px",    // Increase font size to make the text larger
                }}
              >
                Next
              </Button>
            </Col>
          </div>
        </Row>
      </section>

      <section className="copia-registration-footer-content">
        <div className="copia-privacy-policy-links">
          <span>
            <Link href="https://copia.co.ke/privacy-policy/" title="Privacy policy" />
          </span>
          <Divider type="vertical" />
          <span>
            <Link href="https://copia.co.ke/terms-conditions/" title="Terms & Conditions" />
          </span>
        </div>
        <div style={{ fontSize: 14 }}>Copyright @ 2023 Copia Global</div>
      </section>
    </div>
    )
  }

  const newDeliveryLocation = ()=> {
    return (
      <div style={{ padding: 20 }}>
        <div className="heading">
          <Row
              justify={"space-between"}
              align={"middle"}
              style={{ marginBottom: 10 }}
            >
              <Col span={20}>
                <Typography.Title
                  level={4}
                  style={{ textAlign: "left", margin: 0 }}
                >
                  Delivery Location
                </Typography.Title>
              </Col>
              
              <Col span={4}>
                <div className="copia-profile-update-side-logo" />
              </Col>
          </Row>
        </div>

        <div>
          <Row justify={"center"} align={"middle"}>
            <img src="./assets/images/delivery-location-path.svg"></img>
          </Row>
        </div>

        <div className="speechBubble">
          <p>To make sure your order is delivered quickly and easily, we need to know where you are or where you would like it delivered. This way, we can match you with the closest Copia agent.</p>
          <div className="rozzie-head"></div>
        </div>

        <section className="copia-registration-footer-content">
          <div className="row">
            <Row justify={"center"} align={"middle"}>
              <Col span={24}>
                <Button
                  className="copia-profile-submit-button"
                  onClick={handleNext}
                  style={{
                    marginTop: 90,
                    fontSize: "24px"
                  }}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        <div className="copia-privacy-policy-links mt-24px">
          <span>
            <Link href="https://copia.co.ke/privacy-policy/" title="Privacy policy" />
          </span>
          <Divider type="vertical" />
          <span>
            <Link href="https://copia.co.ke/terms-conditions/" title="Terms & Conditions" />
          </span>
        </div>
        <div style={{ fontSize: 14 }}>Copyright @ 2023 Copia Global</div>
      </section>

       </div>

       
    )
  }

  if(state.agent.is_agent_active) {
    return existingDeliveryLocation();
  }else {
    return newDeliveryLocation();
  }
};
export default DeliveryLocation;
