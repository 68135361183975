const {
  REACT_APP_BASE_URL,
  REACT_APP_X_APP_ID,
  REACT_APP_X_API_KEY,
  REACT_APP_OTP_API_CODE,
  REACT_APP_COMPANY_ID,
  REACT_APP_PASSWORD,
  REACT_APP_API_VERSION,
  REACT_APP_EXISTING_USER_URL,
  REACT_APP_BYPASS_OTP,
  REACT_APP_PLAYSTORE_LINK,
  REACT_APP_REWARD_URL_WITH_QUEUE,
  REACT_APP_REWARD_URL_WITHOUT_QUEUE,
  REACT_APP_APP_ID,
  REACT_APP_PARTNER_ID,
  REACT_APP_COPIA_DOMAIN,
  REACT_APP_MEDIA_DOMAIN,
  REACT_APP_BANNER_PRODUCT_PROMO,
  REACT_APP_BANNER_FREE_DATA,
  REACT_APP_GOOGLE_MAP_KEY,
  REACT_APP_MAP_NEARBY_AGENT_BASE_URL,
  REACT_APP_NEARBY_X_API_KEY,
  REACT_APP_ODOO_URL
} = process.env;

interface Config {
  REACT_APP_BASE_URL: string | undefined;
  REACT_APP_X_APP_ID: string | undefined;
  REACT_APP_X_API_KEY: string | undefined;
  REACT_APP_OTP_API_CODE: string | undefined;
  REACT_APP_COMPANY_ID: string | undefined;
  REACT_APP_PASSWORD: string | undefined;
  REACT_APP_API_VERSION: string | undefined;
  REACT_APP_EXISTING_USER_URL: string | undefined;
  REACT_APP_BYPASS_OTP: string | undefined;
  REACT_APP_PLAYSTORE_LINK: string | undefined;
  REACT_APP_REWARD_URL_WITH_QUEUE: string | undefined;
  REACT_APP_REWARD_URL_WITHOUT_QUEUE: string | undefined;
  REACT_APP_APP_ID: string | undefined;
  REACT_APP_PARTNER_ID: string | undefined;
  REACT_APP_COPIA_DOMAIN: string | undefined;
  REACT_APP_MEDIA_DOMAIN: string | undefined;
  REACT_APP_BANNER_PRODUCT_PROMO: string | undefined;
  REACT_APP_BANNER_FREE_DATA: string | undefined;
  REACT_APP_GOOGLE_MAP_KEY: string | undefined;
  REACT_APP_MAP_NEARBY_AGENT_BASE_URL: string | undefined;
  REACT_APP_NEARBY_X_API_KEY: string | undefined;
  REACT_APP_ODOO_URL : string | undefined
}

const config: Config = {
  REACT_APP_BASE_URL,
  REACT_APP_X_API_KEY,
  REACT_APP_X_APP_ID,
  REACT_APP_COMPANY_ID,
  REACT_APP_OTP_API_CODE,
  REACT_APP_PASSWORD,
  REACT_APP_API_VERSION,
  REACT_APP_EXISTING_USER_URL,
  REACT_APP_BYPASS_OTP,
  REACT_APP_PLAYSTORE_LINK,
  REACT_APP_REWARD_URL_WITH_QUEUE,
  REACT_APP_REWARD_URL_WITHOUT_QUEUE,
  REACT_APP_APP_ID,
  REACT_APP_PARTNER_ID,
  REACT_APP_COPIA_DOMAIN,
  REACT_APP_MEDIA_DOMAIN,
  REACT_APP_BANNER_FREE_DATA,
  REACT_APP_BANNER_PRODUCT_PROMO,
  REACT_APP_GOOGLE_MAP_KEY,
  REACT_APP_MAP_NEARBY_AGENT_BASE_URL,
  REACT_APP_NEARBY_X_API_KEY,
  REACT_APP_ODOO_URL,
};

export { config };
