import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { Spin } from "antd";
import { checkSession, logOutUser } from "../services/app.service";
import { logout } from "../redux/appSlice";

interface PrivateRouteProps {
  isAuthenticated?: boolean;
}

const PrivateRoutes: any = ({
  isAuthenticated,
  ...rest
}: PrivateRouteProps) => {
  const dispatch = useDispatch()
  const [sessionState, setSessionState] = useState<null | any>(null)
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    sessionFunction()
  }, [])

  const sessionFunction = async () => {
    try{
      setLoading(true)
      const session = await checkSession()
      setSessionState(session)
      setLoading(false)
    }
    catch(err){
      setLoading(false)
    }
  }


  if (loading) return <Spin spinning={loading} />
  else {
    if (((sessionState && localStorage.getItem('userAttributes')) || localStorage.getItem('username'))) return <Outlet />
    else {
      logOutUser()
      dispatch(logout())
      return <Navigate to={'/'} />
    }
  }


};

export default PrivateRoutes;
