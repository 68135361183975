// src/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./appSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
  },
});

store.dispatch(() => {});
export default store;
