export const countries = [
    /*{ name: "Andorra", shortForm: "AD", code: "+376" },
    { name: "United Arab Emirates", shortForm: "AE", code: "+971" },
    { name: "Afghanistan", shortForm: "AF", code: "+93" },
    { name: "Antigua and Barbuda", shortForm: "AG", code: "+1" },
    { name: "Anguilla", shortForm: "AI", code: "+1" },
    { name: "Albania", shortForm: "AL", code: "+355" },
    { name: "Armenia", shortForm: "AM", code: "+374" },
    { name: "Angola", shortForm: "AO", code: "+244" },
    { name: "Argentina", shortForm: "AR", code: "+54" },
    { name: "American Samoa", shortForm: "AS", code: "+1" },
    { name: "Austria", shortForm: "AT", code: "+43" },
    { name: "Australia", shortForm: "AU", code: "+61" },
    { name: "Aruba", shortForm: "AW", code: "+297" },
    { name: "Åland Islands", shortForm: "AX", code: "+358" },
    { name: "Azerbaijan", shortForm: "AZ", code: "+994" },
    { name: "Bosnia and Herzegovina", shortForm: "BA", code: "+387" },
    { name: "Barbados", shortForm: "BB", code: "+1" },
    { name: "Bangladesh", shortForm: "BD", code: "+880" },
    { name: "Belgium", shortForm: "BE", code: "+32" },
    { name: "Burkina Faso", shortForm: "BF", code: "+226" },
    { name: "Bulgaria", shortForm: "BG", code: "+359" },
    { name: "Bahrain", shortForm: "BH", code: "+973" },
    { name: "Burundi", shortForm: "BI", code: "+257" },
    { name: "Benin", shortForm: "BJ", code: "+229" },
    { name: "Saint Barthélemy", shortForm: "BL", code: "+590" },
    { name: "Bermuda", shortForm: "BM", code: "+1" },
    { name: "Brunei", shortForm: "BN", code: "+673" },
    { name: "Bolivia", shortForm: "BO", code: "+591" },
    { name: "Brazil", shortForm: "BR", code: "+55" },
    { name: "The Bahamas", shortForm: "BS", code: "+1" },
    { name: "Bhutan", shortForm: "BT", code: "+975" },
    { name: "Bouvet Island", shortForm: "BV", code: "+47" },
    { name: "Botswana", shortForm: "BW", code: "+267" },
    { name: "Belarus", shortForm: "BY", code: "+375" },
    { name: "Belize", shortForm: "BZ", code: "+501" },
    { name: "Canada", shortForm: "CA", code: "+1" },
    { name: "Cocos (Keeling) Islands", shortForm: "CC", code: "+61" },
    { name: "Democratic Republic of the Congo", shortForm: "CD", code: "+243" },
    { name: "Central African Republic", shortForm: "CF", code: "+236" },
    { name: "Republic of the Congo", shortForm: "CG", code: "+242" },
    { name: "Switzerland", shortForm: "CH", code: "+41" },
    { name: "Ivory Coast", shortForm: "CI", code: "+225" },
    { name: "Cook Islands", shortForm: "CK", code: "+682" },
    { name: "Chile", shortForm: "CL", code: "+56" },
    { name: "Cameroon", shortForm: "CM", code: "+237" },
    { name: "China", shortForm: "CN", code: "+86" },
    { name: "Colombia", shortForm: "CO", code: "+57" },
    { name: "Costa Rica", shortForm: "CR", code: "+506" },
    { name: "Cuba", shortForm: "CU", code: "+53" },
    { name: "Cape Verde", shortForm: "CV", code: "+238" },
    { name: "Curaçao", shortForm: "CW", code: "+599" },
    { name: "Christmas Island", shortForm: "CX", code: "+61" },
    { name: "Cyprus", shortForm: "CY", code: "+357" },
    { name: "Czech Republic", shortForm: "CZ", code: "+420" },
    { name: "Germany", shortForm: "DE", code: "+49" },
    { name: "Djibouti", shortForm: "DJ", code: "+253" },
    { name: "Denmark", shortForm: "DK", code: "+45" },
    { name: "Dominica", shortForm: "DM", code: "+1" },
    { name: "Dominican Republic", shortForm: "DO", code: "+1" },
    { name: "Algeria", shortForm: "DZ", code: "+213" },
    { name: "Ecuador", shortForm: "EC", code: "+593" },
    { name: "Estonia", shortForm: "EE", code: "+372" },
    { name: "Egypt", shortForm: "EG", code: "+20" },
    { name: "Eritrea", shortForm: "ER", code: "+291" },
    { name: "Spain", shortForm: "ES", code: "+34" },
    { name: "Ethiopia", shortForm: "ET", code: "+251" },
    { name: "European Union", shortForm: "EU", code: "+388" },
    { name: "Finland", shortForm: "FI", code: "+358" },
    { name: "Fiji", shortForm: "FJ", code: "+679" },
    { name: "Falkland Islands", shortForm: "FK", code: "+500" },
    { name: "Federated States of Micronesia", shortForm: "FM", code: "+691" },
    { name: "Faroe Islands", shortForm: "FO", code: "+298" },
    { name: "France", shortForm: "FR", code: "+33" },
    { name: "Gabon", shortForm: "GA", code: "+241" },*/
    { name: "Kenya", shortForm: "KE", code: "+254" },
    { name: "United Kingdom", shortForm: "GB", code: "+44" },
    /*
    { name: "England", shortForm: "GB-ENG", code: "+44" },
    { name: "Northern Ireland", shortForm: "GB-NIR", code: "+44" },
    { name: "Scotland", shortForm: "GB-SCT", code: "+44" },
    { name: "Wales", shortForm: "GB-WLS", code: "+44" },
    { name: "Grenada", shortForm: "GD", code: "+1" },
    { name: "Georgia", shortForm: "GE", code: "+995" },
    { name: "French Guiana", shortForm: "GF", code: "+594" },
    { name: "Guernsey", shortForm: "GG", code: "+44" },
    { name: "Ghana", shortForm: "GH", code: "+233" },
    { name: "Gibraltar", shortForm: "GI", code: "+350" },
    { name: "Greenland", shortForm: "GL", code: "+299" },
    { name: "Gambia", shortForm: "GM", code: "+220" },
    { name: "Guinea", shortForm: "GN", code: "+224" },
    { name: "Guadeloupe", shortForm: "GP", code: "+590" },
    { name: "Equatorial Guinea", shortForm: "GQ", code: "+240" },
    { name: "Greece", shortForm: "GR", code: "+30" },
    { name: "South Georgia and the South Sandwich Islands", shortForm: "GS", code: "+500" },
    { name: "Guatemala", shortForm: "GT", code: "+502" },
    { name: "Guam", shortForm: "GU", code: "+1" },
    { name: "Guinea-Bissau", shortForm: "GW", code: "+245" },
    { name: "Guyana", shortForm: "GY", code: "+592" },
    { name: "Hong Kong", shortForm: "HK", code: "+852" },
    { name: "Heard Island and McDonald Islands", shortForm: "HM", code: "+672" },
    { name: "Honduras", shortForm: "HN", code: "+504" },
    { name: "Croatia", shortForm: "HR", code: "+385" },
    { name: "Haiti", shortForm: "HT", code: "+509" },
    { name: "Hungary", shortForm: "HU", code: "+36" },
    { name: "Indonesia", shortForm: "ID", code: "+62" },
    { name: "Ireland", shortForm: "IE", code: "+353" },
    { name: "Israel", shortForm: "IL", code: "+972" },
    { name: "Isle of Man", shortForm: "IM", code: "+44" },
    { name: "India", shortForm: "IN", code: "+91" },
    { name: "British Indian Ocean Territory", shortForm: "IO", code: "+246" },
    { name: "Iraq", shortForm: "IQ", code: "+964" },
    { name: "Iran", shortForm: "IR", code: "+98" },
    { name: "Iceland", shortForm: "IS", code: "+354" },
    { name: "Italy", shortForm: "IT", code: "+39" },
    { name: "Jersey", shortForm: "JE", code: "+44" },
    { name: "Jamaica", shortForm: "JM", code: "+1" },
    { name: "Jordan", shortForm: "JO", code: "+962" },
    { name: "Japan", shortForm: "JP", code: "+81" },*/
    /*{ name: "Kyrgyzstan", shortForm: "KG", code: "+996" },
    { name: "Cambodia", shortForm: "KH", code: "+855" },
    { name: "Kiribati", shortForm: "KI", code: "+686" },
    { name: "Comoros", shortForm: "KM", code: "+269" },
    { name: "Saint Kitts and Nevis", shortForm: "KN", code: "+1" },
    { name: "North Korea", shortForm: "KP", code: "+850" },
    { name: "South Korea", shortForm: "KR", code: "+82" },
    { name: "Kuwait", shortForm: "KW", code: "+965" },
    { name: "Cayman Islands", shortForm: "KY", code: "+1" },
    { name: "Kazakhstan", shortForm: "KZ", code: "+7" },
    { name: "Laos", shortForm: "LA", code: "+856" },
    { name: "Lebanon", shortForm: "LB", code: "+961" },
    { name: "Saint Lucia", shortForm: "LC", code: "+1" },
    { name: "LGBT", shortForm: "LGBT" },
    { name: "Liechtenstein", shortForm: "LI", code: "+423" },
    { name: "Sri Lanka", shortForm: "LK", code: "+94" },
    { name: "Liberia", shortForm: "LR", code: "+231" },
    { name: "Lesotho", shortForm: "LS", code: "+266" },
    { name: "Lithuania", shortForm: "LT", code: "+370" },
    { name: "Luxembourg", shortForm: "LU", code: "+352" },
    { name: "Latvia", shortForm: "LV", code: "+371" },
    { name: "Libya", shortForm: "LY", code: "+218" },
    { name: "Morocco", shortForm: "MA", code: "+212" },
    { name: "Monaco", shortForm: "MC", code: "+377" },
    { name: "Moldova", shortForm: "MD", code: "+373" },
    { name: "Montenegro", shortForm: "ME", code: "+382" },
    { name: "Saint Martin", shortForm: "MF", code: "+590" },
    { name: "Madagascar", shortForm: "MG", code: "+261" },
    { name: "Marshall Islands", shortForm: "MH", code: "+692" },
    { name: "North Macedonia", shortForm: "MK", code: "+389" },
    { name: "Mali", shortForm: "ML", code: "+223" },
    { name: "Myanmar", shortForm: "MM", code: "+95" },
    { name: "Mongolia", shortForm: "MN", code: "+976" },
    { name: "Macau", shortForm: "MO", code: "+853" },
    { name: "Northern Mariana Islands", shortForm: "MP", code: "+1" },
    { name: "Martinique", shortForm: "MQ", code: "+596" },
    { name: "Mauritania", shortForm: "MR", code: "+222" },
    { name: "Montserrat", shortForm: "MS", code: "+1" },
    { name: "Malta", shortForm: "MT", code: "+356" },
    { name: "Mauritius", shortForm: "MU", code: "+230" },
    { name: "Maldives", shortForm: "MV", code: "+960" },
    { name: "Malawi", shortForm: "MW", code: "+265" },
    { name: "Mexico", shortForm: "MX", code: "+52" },
    { name: "Malaysia", shortForm: "MY", code: "+60" },
    { name: "Mozambique", shortForm: "MZ", code: "+258" },
    { name: "Namibia", shortForm: "NA", code: "+264" },
    { name: "New Caledonia", shortForm: "NC", code: "+687" },
    { name: "Niger", shortForm: "NE", code: "+227" },
    { name: "Norfolk Island", shortForm: "NF", code: "+672" },
    { name: "Nigeria", shortForm: "NG", code: "+234" },
    { name: "Nicaragua", shortForm: "NI", code: "+505" },
    { name: "Netherlands", shortForm: "NL", code: "+31" },
    { name: "Norway", shortForm: "NO", code: "+47" },
    { name: "Nepal", shortForm: "NP", code: "+977" },
    { name: "Nauru", shortForm: "NR", code: "+674" },
    { name: "Niue", shortForm: "NU", code: "+683" },
    { name: "New Zealand", shortForm: "NZ", code: "+64" },
    { name: "Oman", shortForm: "OM", code: "+968" },
    { name: "Panama", shortForm: "PA", code: "+507" },
    { name: "Peru", shortForm: "PE", code: "+51" },
    { name: "French Polynesia", shortForm: "PF", code: "+689" },
    { name: "Papua New Guinea", shortForm: "PG", code: "+675" },
    { name: "Philippines", shortForm: "PH", code: "+63" },
    */
    { name: "Pakistan", shortForm: "PK", code: "+92" },
    /*
    { name: "Poland", shortForm: "PL", code: "+48" },
    { name: "Saint Pierre and Miquelon", shortForm: "PM", code: "+508" },
    { name: "Pitcairn Islands", shortForm: "PN", code: "+64" },
    { name: "Puerto Rico", shortForm: "PR", code: "+1" },
    { name: "Palestine", shortForm: "PS", code: "+970" },
    { name: "Portugal", shortForm: "PT", code: "+351" },
    { name: "Palau", shortForm: "PW", code: "+680" },
    { name: "Paraguay", shortForm: "PY", code: "+595" },
    { name: "Qatar", shortForm: "QA", code: "+974" },
    { name: "Réunion", shortForm: "RE", code: "+262" },
    { name: "Romania", shortForm: "RO", code: "+40" },
    { name: "Serbia", shortForm: "RS", code: "+381" },
    { name: "Russia", shortForm: "RU", code: "+7" },
    { name: "Rwanda", shortForm: "RW", code: "+250" },
    { name: "Saudi Arabia", shortForm: "SA", code: "+966" },
    { name: "Solomon Islands", shortForm: "SB", code: "+677" },
    { name: "Seychelles", shortForm: "SC", code: "+248" },
    { name: "Sudan", shortForm: "SD", code: "+249" },
    { name: "Sweden", shortForm: "SE", code: "+46" },
    { name: "Singapore", shortForm: "SG", code: "+65" },
    { name: "Saint Helena", shortForm: "SH", code: "+290" },
    { name: "Slovenia", shortForm: "SI", code: "+386" },
    { name: "Svalbard and Jan Mayen", shortForm: "SJ", code: "+47" },
    { name: "Slovakia", shortForm: "SK", code: "+421" },
    { name: "Sierra Leone", shortForm: "SL", code: "+232" },
    { name: "San Marino", shortForm: "SM", code: "+378" },
    { name: "Senegal", shortForm: "SN", code: "+221" },
    { name: "Somalia", shortForm: "SO", code: "+252" },
    { name: "Suriname", shortForm: "SR", code: "+597" },
    { name: "South Sudan", shortForm: "SS", code: "+211" },
    { name: "São Tomé and Príncipe", shortForm: "ST", code: "+239" },
    { name: "El Salvador", shortForm: "SV", code: "+503" },
    { name: "Sint Maarten", shortForm: "SX", code: "+1" },
    { name: "Syria", shortForm: "SY", code: "+963" },
    { name: "Eswatini", shortForm: "SZ", code: "+268" },
    { name: "Turks and Caicos Islands", shortForm: "TC", code: "+1" },
    { name: "Chad", shortForm: "TD", code: "+235" },
    { name: "French Southern and Antarctic Lands", shortForm: "TF", code: "+262" },
    { name: "Togo", shortForm: "TG", code: "+228" },
    { name: "Thailand", shortForm: "TH", code: "+66" },
    { name: "Tajikistan", shortForm: "TJ", code: "+992" },
    { name: "Tokelau", shortForm: "TK", code: "+690" },
    { name: "Timor-Leste", shortForm: "TL", code: "+670" },
    { name: "Turkmenistan", shortForm: "TM", code: "+993" },
    { name: "Tunisia", shortForm: "TN", code: "+216" },
    { name: "Tonga", shortForm: "TO", code: "+676" },
    { name: "Turkey", shortForm: "TR", code: "+90" },
    { name: "Trinidad and Tobago", shortForm: "TT", code: "+1" },
    { name: "Tuvalu", shortForm: "TV", code: "+688" },
    { name: "Taiwan", shortForm: "TW", code: "+886" },
    { name: "Tanzania", shortForm: "TZ", code: "+255" },
    { name: "Ukraine", shortForm: "UA", code: "+380" },
    { name: "Uganda", shortForm: "UG", code: "+256" },
    { name: "United States Minor Outlying Islands", shortForm: "UM", code: "+1" },
    { name: "United States", shortForm: "US", code: "+1" },
    { name: "California", shortForm: "US-CA", code: "+1" },
    { name: "Uruguay", shortForm: "UY", code: "+598" },
    { name: "Uzbekistan", shortForm: "UZ", code: "+998" },
    { name: "Vatican City", shortForm: "VA", code: "+379" },
    { name: "Saint Vincent and the Grenadines", shortForm: "VC", code: "+1" },
    { name: "Venezuela", shortForm: "VE", code: "+58" },
    { name: "British Virgin Islands", shortForm: "VG", code: "+1" },
    { name: "U.S. Virgin Islands", shortForm: "VI", code: "+1" },
    { name: "Vietnam", shortForm: "VN", code: "+84" },
    { name: "Vanuatu", shortForm: "VU", code: "+678" },
    { name: "Wallis and Futuna", shortForm: "WF", code: "+681" },
    { name: "Samoa", shortForm: "WS", code: "+685" },
    { name: "Kosovo", shortForm: "XK", code: "+383" },
    { name: "Yemen", shortForm: "YE", code: "+967" },
    { name: "Mayotte", shortForm: "YT", code: "+262" },
    { name: "South Africa", shortForm: "ZA", code: "+27" },
    { name: "Zambia", shortForm: "ZM", code: "+260" },
    { name: "Zimbabwe", shortForm: "ZW", code: "+263" }*/
  ];
  