import { Col, DatePicker, Form, Row } from "antd";

function CopiaDatePicker() {
  return (
    <Row justify={"start"} align={"middle"} gutter={24}>
      <Col span={6}>
        <Form.Item
          label="Date"
          style={{ textAlign: "left", marginBottom: 30 }}
          name="day"
        >
          <DatePicker
            format="DD"
            picker="date"
            placeholder="01"
            style={{
              height: 40,
            }}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Month"
          style={{ textAlign: "left", marginBottom: 30 }}
          name="month"
        >
          <DatePicker
            format="MM"
            picker="month"
            placeholder="02"
            style={{
              height: 40,
            }}
          />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          label="Year"
          style={{ textAlign: "left", marginBottom: 30 }}
          name="year"
        >
          <DatePicker
            format="YYYY"
            placeholder="1995"
            picker="year"
            style={{
              height: 40,
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default CopiaDatePicker;
