import React, { useCallback, useEffect, useState } from 'react';
import markerIcon from "../../../assets/images/location-pin-animated.gif";
import userMarkerPin from "../../../assets/images/user-location-pin.svg";
import { GoogleMap, useJsApiLoader, StandaloneSearchBox, Marker } from '@react-google-maps/api';
import { config } from '../../../utils/config';

const MapWithSearchBox = (props: any) => {
    const [map, setMap] = useState<any>(null);
    const [searchBox, setSearchBox] = useState<any>(null);
    const [zoomlvl, setZoom] = useState(16);
    const [center, setCenter] = useState({ lat: -34.397, lng: 150.644 });

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: config.REACT_APP_GOOGLE_MAP_KEY!, // Replace with your API key
        libraries: ['places'],
    });

    const onMapLoad = (map: any) => {
        setMap(map);
    };

    const onSearchBoxLoad = (ref: any) => {
        setSearchBox(ref);
    };

    const onPlacesChanged = () => {
        const places = searchBox!.getPlaces();
        const bounds = new window.google.maps.LatLngBounds();

        places.forEach((place: any) => {
            if (!place.geometry || !place.geometry.location) return;
            console.log(place.geometry.location.lat(), place.geometry.location.lng())
            const lat = place.geometry.location.lat()
            const lng = place.geometry.location.lng()

            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            props.setCords(lat, lng)
        });

        map.fitBounds(bounds);
        setCenter(bounds.getCenter().toJSON());
    };

    const onUnmount = useCallback(function callback(getMap: any) {
        setMap([])
    }, []);

    const customMarker = {
        url: markerIcon,
        // scaledSize: typeof typeof google?.maps !== "undefined" ? new google!.maps!.Size(30, 50) : ''
        scaledSize: ''

    }
    const markerClick = (marker: any) => {
        props.markerClick(marker);
    }
    const userMarker = {
        url: userMarkerPin
    }




    let timeout: any
    useEffect(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (props.zoom) {
                setZoom(props.zoom);
            }
            if (props.center) {
                //setcenterCords(props.center);
                panMapPosition(props.center);
            }
        }, 100);
    }, [props.zoom, props.center]);

    const panMapPosition = (cords: any) => {
        if (map) {
            map.panTo(cords);
        }
    }

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div>
            <StandaloneSearchBox
                onLoad={onSearchBoxLoad}
                onPlacesChanged={onPlacesChanged}
            >
                <input
                    type="text"
                    placeholder="Search places..."
                // style={{ width: 200, height: 20, marginBottom: 10 }}
                />
            </StandaloneSearchBox>
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                // center={center}
                zoom={zoomlvl}
                onLoad={onMapLoad}
                onClick={props.onMapClick}
                onUnmount={onUnmount}
            >
                {props.markers ? props.markers.map(
                    (marker: any, idx: number) => {
                        if (marker.lat) {
                            return (
                                <Marker
                                    position={{ lat: marker.lat, lng: marker.lng }}
                                    icon={marker.icon ? marker.icon : customMarker}
                                    key={idx}
                                    onClick={(e) => { markerClick(marker) }}
                                />
                            )
                        }
                    }) : ''}
                {
                    props.userMarker ? <Marker
                        position={{ lat: props.userMarker.lat, lng: props.userMarker.lng }}
                        icon={userMarker} 
                        // onClick={(e) => { markerClick(props.userMarker) }} 
                        /> : ''
                }
            </GoogleMap>
        </div>
    );
};

export default MapWithSearchBox;
