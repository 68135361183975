import { Anchor, Button, Divider, Typography, Col, Row, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { config } from "../../utils/config";
import CopiaAppDownload from "../../assets/images/download-copia-app.svg";
import GOOGLE from "../../assets/images/google-play-button.svg";

import Banner from "../../components/shared/Banner/Banner";

import "./Reminder.scss";
import SVGToComponent from "../../components/shared/icons/icons";
import { Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { logOutUser } from "../../services/app.service";

const Reminder: React.FC<any> = () => {
  const state = useSelector((state: any) => state.app.cognitoPayload);
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() =>{
    if(!location.state.isConfirm) navigate('/profile-update') 
    logOutUser()
    setTimeout(() =>{
      window.location.replace(process.env.REACT_APP_REDIRECT_URL!);
    },120000)
  },[])

  return (
    <div className="copia-registration-main-container">
      <section className="copia-registration-header-content">
        <Row justify={"center"} align={"middle"}>
          <div className="copia-registration-container">
            <Col span={24}>
              <Row justify={"center"} align={"middle"}>
                <div className="copia-logo" />
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Title level={3}>Hey</Typography.Title>
              <Typography.Title level={3}>
                {state.attributes?.given_name || ""}
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Typography.Paragraph className="reminder-text">
                It seems you've already created your profile and gotten the
                100Mb. If you haven't already done so, download the App now to
                get shopping and take advantage of our great exclusive offers!
              </Typography.Paragraph>
            </Col>
            <Col span={24}>
              <Suspense fallback={<Skeleton.Image active />}>
                <Banner url={`${config.REACT_APP_BANNER_PRODUCT_PROMO}`} />
              </Suspense>
            </Col>
            <Col span={24}>
              <Typography.Paragraph className="reminder-secondary-text">
                Don’t miss out on these great offers from Copia. Download Copia
                app today and start saving
              </Typography.Paragraph>
            </Col>
            <Col span={24} className="copia-promotion-app-download-button">
              <Button
                type="primary"
                className="copia-button"
                onClick={() =>
                  (window.location.href = config.REACT_APP_PLAYSTORE_LINK || "")
                }
              >
                Download Copia App <SVGToComponent icon={CopiaAppDownload} />
              </Button>
            </Col>
            <Col span={24}>
              <img
                src={GOOGLE}
                alt="gogle"
                onClick={() =>
                  (window.location.href = config.REACT_APP_PLAYSTORE_LINK || "")
                }
              />
            </Col>
          </div>
        </Row>
      </section>
      <section className="copia-registration-footer-content">
        <div className="copia-privacy-policy-links">
          <span>
            <Anchor.Link href="https://google.com" title="Privacy policy" />
          </span>
          <Divider type="vertical" />
          <span>
            <Anchor.Link href="https://google.com" title="Terms & Conditions" />
          </span>
        </div>
        <div style={{ fontSize: 14 }}>Copyright @ 2023 Copia Global</div>
      </section>
    </div>
  );
};
export default Reminder;
