import {
  Anchor,
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Spin,
  Typography,
  notification,
} from "antd";
import CountryDropdown from "../../components/shared/CountryDropdown";
import SVGToComponent from "../../components/shared/icons/icons";
import CallIcon from "../../assets/images/Call.svg";
import Person from "../../assets/images/dialog-face.svg";
import Banner from "../../components/shared/Banner/Banner";

import { useNavigate } from "react-router";
import { Link as ReactLink } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { dispatchOTPSentAction, setUserSigninState } from "../../redux/appSlice";
import { cognitoSignIn, cognitoSignup, resendOtp } from "../../services/app.service";
import { config } from "../../utils/config";
import "./Registration.scss";
import Dialog from "../../components/shared/Dialog/Dialog";

const { Link } = Anchor;

const Registration: React.FC<any> = () => {
  const [loader, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values: any) => {
    const countryCode = values.code || "+254"
    const phone = `${countryCode}${values.phoneNumber}`;
  
    try {
      setLoading(true);
      dispatch(
        dispatchOTPSentAction({
          phone,
        })
      );

        const obj = {
          username: phone,
          attributes: {
            family_name: 'NOT_PROVIDED',
            gender: 'NOT_PROVIDED',
            given_name: 'NOT_PROVIDED',
            phone_number: phone,
            birthdate: '01-01-1990',
            ['custom:otpData']: 'NOT_PROVIDED',
            address: 'NOT_PROVIDED',
            ['custom:agent']: 'NOT_PROVIDED',
           ...(values.referalCode && {['custom:referal']:values.referalCode.toString()}) 
            
          },

        }
        await cognitoSignup(obj);
        const user = await cognitoSignIn({ username: phone })
        dispatch(setUserSigninState(user))
        navigate("/phone-verification")

      setLoading(false);
    } catch (ex: any) {
      if (ex?.message === 'An account with the given phone_number already exists.') {
        const user = await cognitoSignIn({ username: phone })
        dispatch(setUserSigninState(user))
        notification.error({
          message: ex.message,
        });
        navigate("/phone-verification")
      }
      setLoading(false);
    }

  };
  return (
    <Spin spinning={loader}>
      <div className="copia-registration-main-container">
        <section className="copia-registration-header-content">
          <Row justify={"center"} align={"middle"}>
            <div className="copia-registration-container">
              <Col span={24}>
                <Row justify={"center"} align={"middle"}>
                <ReactLink to="/"><div className="copia-logo" /></ReactLink>    
                </Row>
              </Col>
              <Col span={24}>
                <Typography.Title level={3}>Sign in here</Typography.Title>
              </Col>
              <Col span={24}>
                <Form onFinish={onSubmit}>
                  <Form.Item name={"code"}>
                    <CountryDropdown />
                  </Form.Item>
                  <Form.Item name={"phoneNumber"} rules={[{ required: true }]}>
                    <InputNumber
                      className="copia-phone-input"
                      prefix={<SVGToComponent icon={CallIcon} />}
                      placeholder="Enter the Phone Number"
                      inputMode={"numeric"}
                    />
                  </Form.Item> <Form.Item name={"referalCode"}>
                    <InputNumber
                      className="copia-phone-input"
                      // prefix={<SVGToComponent icon={CallIcon} />}
                      placeholder="Enter the Referral Code"
                      inputMode={"numeric"}
                      // disabled
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="copia-registration-submit-button"
                      htmlType="submit"
                      style={{
                        background: "#FECD03",
                        fontSize: "24px",    // Increase font size to make the text larger
                      }}
                    >
                      Continue
                    </Button>
                  </Form.Item>
                  <Col span={24}>
                    <Banner url={`${config.REACT_APP_BANNER_PRODUCT_PROMO}`} />
                  </Col>
                </Form>

              </Col >

            </div>

            <Col span={24} className="welcome-screen-dialog mt-24px mb-24px">
              <Dialog
                text={
                  "Say goodbye to data worries! You will not be charged for data while using this website or Copia app."
                }
                image={Person}
              />
            </Col>
          </Row>
          <Row justify={"start"} align={"middle"}>
            <Col span={24}>
              <Typography.Title level={5}>Why Sign in?</Typography.Title>
            </Col>
            <Col span={24}>
              <div className="why-signin-container">
                <ul style={{ textAlign: "left" }}>
                  <li>No Agent needed to order</li>
                  <li>No data cost for using Copia App</li>
                  <li>Exclusive app only offers every day</li>
                  <li>
                    Guaranteed delivery to your nearest Copia Agent upcountry
                  </li>
                  <li>
                    Your Agent is your friend in success, earning with every
                    order you place
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </section>
        <section className="copia-registration-footer-content">
          <div className="copia-privacy-policy-links">
            <span>
              <Link
                href="https://copia.co.ke/privacy-policy/"
                title="Privacy policy"
              />
            </span>
            <Divider type="vertical" />
            <span>
              <Link
                href="https://copia.co.ke/terms-conditions/"
                title="Terms & Conditions"
              />
            </span>
          </div>
          <div style={{ fontSize: 14 }}>Copyright @ 2023 Copia Global</div>
        </section>
      </div>
    </Spin>
  );
};
export default Registration;
