import { useNavigate } from "react-router";
import { Button, Col, Row, Typography, notification } from "antd";
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';


import GMap from "../../../components/shared/Map/map";

import { setCognitoPayload } from "../../../redux/appSlice";

import { useSelector, useDispatch } from "react-redux";

import backArrow from "../../../assets/images/arrow-back.svg";
import locationPin from "../../../assets/images/location-pin.svg";
import gpsIcon from "../../../assets/images/gps-icon.svg";
import closeIcon from "../../../assets/images/close-icon.svg";

import { nearbyAgentsCords } from "../../../services/app.service";

import "../MapLocation/MapLocation.scss";

import { useEffect, useState, useRef } from "react";

const MapLocation: React.FC<any> = () => {
    const navigate = useNavigate();
    const inputRef: any = useRef()
    const [centerCords, setCenterCords]: any = useState("");
    const [zoomlvl, setZoomlvl]: any = useState(13);
    const [locations, setLocations]: any = useState([
        {   //Default Kenya Cords
            lat: 0.40865314780010065,
            lng: 37.816215395426,
            icon: " "
        }
    ]);
    const [userCords, setUserCords] = useState<any>(null);
    const dispatch = useDispatch();

    const marektDefault = {
        name: "", shop: "", lat: "", lng: "", display_name: ""
    };

    const [markerData, setMarkerData] = useState(marektDefault);

    const [loading, setLoading] = useState(false);

    const getUserCords: any = () => {
        if (navigator.geolocation) {

            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };

            navigator.geolocation.getCurrentPosition((pos) => {

                const crd = pos.coords;
                const lat = crd.latitude;
                const lng = crd.longitude;
                const searchRadius = 5000;

                // const lat = 0.3468589273102303;
                // const lng = 36.31574490139254;

                setCenterCords({
                    lat: lat,
                    lng: lng

                })


                setUserCords({ lat: lat, lng: lng });


                nearbyAgentsCords({
                    "latitude": lat,
                    "longitude": lng
                }, searchRadius).then((res) => {
                    let locations: any = [];
                    if (res.dbResponse) {
                        res.dbResponse.forEach((agent: any) => {
                            const getJson = JSON.parse(agent.geoJson.S);
                            locations.push({ lat: parseFloat(getJson.coordinates[1]) || 0, lng: parseFloat(getJson.coordinates[0]) || 0, name: agent.name.S, display_name: agent.display_name.S });
                        })
                    }
                    setLocations(locations);
                    setLoading(false);
                    setZoomlvl(17);
                });


            }, (err) => {
                console.warn(`ERROR(${err.code}): ${err.message}`)
            }, options);
        }
    }

    let timeout: any;
    useEffect(()=>{
        if(!state.cognitoPayload.username) {
            notification.error({
                message: "something unexpected happened please try again later.",
              });
            navigate("/profile-update");
        }
    })


    const state = useSelector((state: any) => state.app);

    const confirmLocation = () => {

        const payload = state.cognitoPayload;

        const cognitoPayload = {
            username: payload.username,
            attributes: {
                ...state.cognitoPayload.attributes,
                agent: JSON.stringify({
                    "geolocation":
                    {
                        "lat": markerData.lat,
                        "lng": markerData.lng
                    },
                    "name": markerData.name,
                    "display_name": markerData.display_name
                })
            }
        };

        dispatch(setCognitoPayload(cognitoPayload));

        navigate("/confirmation");
    }

    const onMapClick = (e: any) => {
        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        setCords(lat,lng)
    }

    const setCords = (lat:number,lng:number) =>{
        setUserCords({ lat, lng });
        setCenterCords({ lat, lng })
        setLoading(true)
        nearbyAgentsCords({
            "latitude": lat,
            "longitude": lng
        }, 5000)
            .then((res) => {
                let locations: any = [];
                if (res.dbResponse) {
                    res.dbResponse.forEach((agent: any) => {
                        const getJson = JSON.parse(agent.geoJson.S);
                        locations.push({ lat: parseFloat(getJson.coordinates[1]) || 0, lng: parseFloat(getJson.coordinates[0]) || 0, name: agent.name.S, display_name: agent.display_name.S });
                    })
                }
                setLocations(locations);
                setZoomlvl(17);
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }


    return (
        <div>

            <div>
                <Row
                    justify={"start"}
                    align={"middle"}
                    style={{ padding: 20 }}
                    onClick={() => {
                        navigate("/profile-update")
                    }}
                >

                    <Col span={3}>
                        <div className="arrow-back-icon text-left">
                            <img alt={"Back"} src={backArrow}></img>
                        </div>
                    </Col>

                    <Col span={15}>
                        <div className="d-inline-block">
                            <Typography.Title
                                level={4}
                                style={{ textAlign: "left", margin: 0 }}
                            >
                                Select delivery point
                            </Typography.Title>
                        </div>
                    </Col>
                </Row>
            </div>


            <section className="mapView">
                <div id="map">
                    <GMap center={centerCords} zoom={zoomlvl} markers={locations} userMarker={userCords} markerClick={setMarkerData} loading={loading} onMapClick={onMapClick} setCords={setCords} ></GMap>

                </div>
            </section>

            <section className="copia-registration-footer-content">
                <Row
                    justify={"start"}
                    align={"middle"}
                    style={{ marginBottom: 10, padding: 10 }}
                >

                    <Col span={3}>
                        <div className="arrow-back-icon text-left">
                            <img alt={"Location"} src={locationPin}></img>
                        </div>
                    </Col>

                    <Col span={20}>
                        <div className="d-inline-block text-left">
                            <p className="f-16">Copia delivery points</p>
                        </div>
                    </Col>
                </Row>
                <Row justify={"center"} align={"middle"}>
                    <Col span={24}>
                        <div className="inline-block">
                            <Button
                                className="copia-profile-submit-button w-auto"
                                onClick={() => getUserCords()}
                                style={{ fontSize: "18px" }}
                            >
                                <img alt={"GPS"} src={gpsIcon}></img> &nbsp;Use my current location
                            </Button>
                        </div>
                    </Col>
                </Row>


                <div id="confirmationModal" className={markerData.name ? "open" : ""}>
                    <div className="confirmationContent">
                        <div className="heading">
                            <h3>Delivery Point</h3>
                            <div onClick={() => setMarkerData(marektDefault)}><img alt={"Close"} src={closeIcon}></img></div>
                        </div>
                        <div className="content">
                            <div>
                                <label>Shop Owner</label>
                                <p>{markerData.name || ''}</p>
                            </div>
                            {/* <div>
                                <label>Shop Name & Location</label>
                                <h4><p>Janes</p></h4>
                                <p>Eastern Bypass</p>
                                <a href="">See shop photo </a>
                            </div> */}

                            <Button
                                className="copia-profile-submit-button"
                                onClick={() => confirmLocation()}
                                style={{ fontSize: "18px" }}
                            >
                                Confirm Location
                            </Button>
                        </div>
                    </div>
                </div>

            </section>

        </div>
    )
}

export default MapLocation;