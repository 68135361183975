import { IProductBannerProps } from "./Banner.interface";
import "./Banner.scss";

const Banner: React.FC<IProductBannerProps> = (props: IProductBannerProps) => {
  return (
    <div className="banner-container">
      <img className="banner" alt="promotions" src={props.url} />
    </div>
  );
};

export default Banner;
