import { Auth } from "aws-amplify";
import { http, httpExistingUser, httpReward, httpWithCopiaDomain, httpMapURL, httpCreateOdooUser } from "../utils/client";
import {
  EXISTING_CUSTOMER_URL,
  GET_PROMO_OFFERS_URL,
  OTP_VERIFICATION_URL,
  REGISTRATION_URL,
  REWARD_WITH_QUEUE_URL,
  AGENTS_NEARBY_URL,
  CREATE_ODOO_USER
} from "./app.endpoints";
import {
  ICognitoSignInPayload,
  ICognitoSignupPayload,
  ICognitoUpdatePayload,
  IExistingUserPayload,
  IOTPVerificationPayload,
  IObject,
  IOtpVerification,
  IRegistrationPayload,
  IRegistrationResponse,
  IResendOtpPayload,
} from "./app.interface";
import { config } from "../utils/config";

export const registration = async (
  body: IRegistrationPayload
): Promise<IRegistrationResponse> => {
  try {
    const response = await http.post(REGISTRATION_URL, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOtp = async (
  body: IOTPVerificationPayload
): Promise<any> => {
  try {
    const response = await http.post(OTP_VERIFICATION_URL, body);
    return response;
  } catch (ex) {
    throw ex;
  }
};

export const resendOtp = async (
  body: IResendOtpPayload
): Promise<any> => {
  try {
    const response = await Auth.resendSignUp(body.username);
    return response;
  } catch (ex) {
    throw ex;
  }
};
export const OtpVerification = async (
  { username, otp }: IOtpVerification
): Promise<any> => {
  try {
    const response = await Auth.confirmSignUp(username, otp);
    return response;
  } catch (ex) {
    throw ex;
  }
};

export const cognitoSignup = async (
  // body: ICognitoSignupPayload
  body:any
): Promise<any> => {
  try {
    const response = await Auth.signUp({
      ...body,
      password: config.REACT_APP_PASSWORD as string,
    });
    return response;
  } catch (ex) {
    throw ex;
  }
};

export const cognitoSignIn = async (
  { username }: ICognitoSignInPayload
): Promise<any> => {
  try {
    const response = await Auth.signIn(username);
    return response
  } catch (ex) {
    throw ex;
  }
};

export const cognitoUpdateUser = async (
  attributes: ICognitoUpdatePayload
): Promise<any> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const response = await Auth.updateUserAttributes(user, {...attributes});
    return response;
  } catch (ex) {
    throw ex;
  }
};

export const fetchExistingUser = async (
  body: IExistingUserPayload
): Promise<any> => {
  try {
    const response = await httpExistingUser.post(EXISTING_CUSTOMER_URL, body);
    return response;
  } catch (ex) {
    throw ex;
  }
};

export const rewardWithQueue = async (body: any): Promise<any> => {
  try {
    const response = await httpReward.post(REWARD_WITH_QUEUE_URL, body);
    return response;
  } catch (ex) {
    throw ex;
  }
};

export const getProductOffers = async (body: any): Promise<any> => {
  try {
    const response = await httpWithCopiaDomain.post(GET_PROMO_OFFERS_URL, body);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

export const nearbyAgentsCords = async (userCords: any, radiusMeter: number): Promise<any> => {
  try {
    const body = {
      "location": userCords,
      "RadiusInMeter": radiusMeter

    }
    const response = await httpMapURL.post(AGENTS_NEARBY_URL, body);

    return response.data;
  } catch (ex) {
    throw ex;
  }
}

export const checkSession = async (): Promise<any> => {
  try {
    const session = await Auth?.currentSession();  
    if (session) return true
    return false
  } catch (error) {
    return false;
  }
}

export const postOdooCustomer = async (body: any): Promise<any> => {
  try {
    const response = await httpCreateOdooUser.post(CREATE_ODOO_USER, body);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};


export const logOutUser = () => {
    localStorage.removeItem('userAttributes')
    localStorage.removeItem('username')
}

