import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  Col,
  Row,
  Typography,
  Spin,
  Descriptions,
  DescriptionsProps,
  Button,
  Checkbox,
  Divider,
  Anchor,
  notification,
} from "antd";
import "./Confirmation.scss";
import { useEffect, useState } from "react";
import { cognitoUpdateUser, postOdooCustomer } from "../../../services/app.service";

const Confirmation: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [termsCheck, setTermsCheck] = useState<boolean>(false);
  const navigate = useNavigate();
  const state = useSelector((state: any) => state.app);

  const agentData = JSON.parse(state.cognitoPayload.attributes?.agent ?? 'agent');
  // const agentData = { display_name: 'locationname' };

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Phone Number",
      style: { fontSize: 14, paddingBottom: 6 },
      children: state.cognitoPayload?.attributes.phone_number || "",
    },
    {
      key: "2",
      label: "Full Name",
      style: { fontSize: 14, paddingBottom: 6 },
      children:
        `${state.cognitoPayload?.attributes.given_name} ${state.cognitoPayload?.attributes.family_name}` ||
        "",
    },
    {
      key: "3",
      label: "Gender",
      style: { fontSize: 14, paddingBottom: 6 },
      children: state.cognitoPayload.attributes.gender || "",
    },
    {
      key: "4",
      label: "Email Address",
      span: 2,
      style: { fontSize: 14, paddingBottom: 6 },
      children: state.cognitoPayload.attributes.email || "",
    },
    {
      key: "5",
      label: "Delivery Location",
      style: { fontSize: 14, paddingBottom: 0 },
      children: agentData?.display_name,
    },
  ];

  useEffect(() =>{
    if(!state.cognitoPayload?.attributes?.agent) navigate('/profile-update')
  },[])

  const onConfirmation = async () => {
    setLoading(true);
    try {
          const obj = {
            gender: state.cognitoPayload.attributes.gender ?? state?.user?.gender,
            given_name: state.cognitoPayload.attributes.given_name ?? state?.user?.given_name,
            family_name: state.cognitoPayload.attributes.family_name ?? state?.user?.family_name,
            birthdate: state.cognitoPayload.attributes.birthdate ?? state?.user?.birthdate,
            ['custom:otpData']: state.cognitoPayload.attributes['custom:otpData'] ?? state?.user['custom:otpData'],
            address: state.cognitoPayload.attributes['custom:otpData'] ?? state?.user?.address,
            ['custom:agent']: JSON.stringify( state.cognitoPayload.attributes?.agent) ?? state?.user['custom:agent'],
            ["custom:partner_id"]:state.cognitoPayload.attributes['custom:partner_id'] ?? state?.user['custom:partner_id']

          }
          console.log(obj)
          await cognitoUpdateUser(obj)
          delete obj["custom:partner_id"]
          await postOdooCustomer({...obj, phone_number_verified:true,...(state?.user?.sub && {sub:state?.user?.sub}),
          ...(state?.user?.['custom:referal'] && {['custom:referal']:state?.user?.['custom:referal']})
         })
        
        setLoading(false);
        navigate("/thank-you",{state:{isConfirm:true,id: state.cognitoPayload.attributes['custom:partner_id'] ?? state?.user['custom:partner_id'] }});
      } catch (err: any) {
        setLoading(false);
        if (err.code === "UsernameExistsException") {
          notification.error({
            message:
              "You're ahead of the game and have completed your profile already. Download the Copia App from the Play Store and take advantage of our great App exclusive deals today!",
          });
        }
        navigate("/reminder",{state:{isConfirm:true}});
      }
    };

    return (
      <Spin spinning={loading}>
        <Row
          justify={"space-between"}
          align={"middle"}
          style={{ marginBottom: 10 }}
          className="profile-confirmation-container"
        >
          <Col span={20} style={{ padding: 20 }}>
            <Typography.Title level={4} style={{ textAlign: "left", margin: 0 }}>
              Update your profile
            </Typography.Title>
          </Col>
          <Col span={4}>
            <div className="copia-profile-update-side-logo" />
          </Col>
          <Col span={24}>
            <div className="copia-banner-container">
              <Col span={24}>
                <Typography.Paragraph className="copia-banner-text">
                  Get 100MB data free once you complete this process
                </Typography.Paragraph>
              </Col>
            </div>
          </Col>
        </Row>
        <Row
          justify={"start"}
          align={"middle"}
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          <Col span={24}>
            <Descriptions
              style={{ textAlign: "left" }}
              layout="vertical"
              items={items}
            />
          </Col>
        </Row>
        <Row
          justify={"start"}
          align={"middle"}
          style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}
        >
          <Col span={24}>
            <Button
              className="copia-edit-profile-button"
              onClick={() => navigate("/profile-update")}
              style={{
                fontSize: "24px",    // Increase font size to make the text larger
              }}
            >
              Edit Profile detail
            </Button>
          </Col>
          <Col span={24} style={{ textAlign: "left", marginTop: 10 }}>
            <Checkbox onChange={(e) => setTermsCheck(e.target.checked)}>
              By continuing you agree to our Terms and Conditions of Use
            </Checkbox>
          </Col>
          <Col span={24} style={{ marginTop: 20 }}>
            <Button
              className="copia-profile-submit-button"
              onClick={onConfirmation}
              disabled={!termsCheck}
              style={{
                fontSize: "24px",    // Increase font size to make the text larger
              }}
            >
              Complete Registration
            </Button>
          </Col>
          <Col span={24}>
            <section
              className="copia-registration-footer-content"
              style={{
                marginTop: 10,
              }}
            >
              <div className="copia-privacy-policy-links">
                <span>
                  <Anchor.Link
                    href="https://copia.co.ke/privacy-policy/"
                    title="Privacy policy"
                  />
                </span>
                <Divider type="vertical" />
                <span>
                  <Anchor.Link
                    href="https://copia.co.ke/terms-conditions/"
                    title="Terms & Conditions"
                  />
                </span>
              </div>
              <div style={{ fontSize: 14 }}>Copyright @ 2023 Copia Global</div>
            </section>
          </Col>
        </Row>
      </Spin>
    );
  };

  export default Confirmation;
