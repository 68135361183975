import RootRoute from "./routes/RootRoute";

import "./App.scss";
import { Auth } from "aws-amplify";
import awsExports from "./aws-exports";

function App() {
  Auth.configure(awsExports);
  return (
    <div className="App">
      <RootRoute />
    </div>
  );
}

export default App;
