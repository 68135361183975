import React, { useState } from "react";
import { AutoComplete } from "antd";
import { FlagIcon } from "react-flag-kit"; // If you're using a flag library
import { countries } from "../../../constants";

const formatCountryOption = (country: any) => (
  <AutoComplete.Option key={country.name} value={country.name}>
    <FlagIcon code={country.shortForm} /> {`${country.name} (${country.code})`}
  </AutoComplete.Option>
);

const CountryDropdown: React.FC<{
  onChange?: (code: string) => void;
}> = ({ onChange }) => {
  const [selectedCountry, setSelectedCountry] = useState<{
    name: string;
    code: string;
    shortForm: string;
  } | null>(countries[0] as any);

  const handleCountrySelect = (value: any) => {
    const selected = countries.find((country) => country.name === value);
    setSelectedCountry(selected as any);
    onChange && onChange(selected?.code || "");
  };

  return (
    <AutoComplete
      style={{ height: 47 }}
      placeholder="Select a country"
      value={`${selectedCountry?.name || ""} ${selectedCountry?.code}`}
      onSelect={handleCountrySelect}
    >
      {countries.map(formatCountryOption)}
    </AutoComplete>
  );
};

export default CountryDropdown;
